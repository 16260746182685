var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import { RepayService } from "~/services/business-service/repay.service";
import * as echarts from "echarts";
var DayCollectionStatistic = /** @class */ (function (_super) {
    __extends(DayCollectionStatistic, _super);
    function DayCollectionStatistic() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.queryParamsModel = {
            startDate: _this.formattedStartDate(),
            endDate: _this.formattedEndDate(),
        };
        _this.dataSet = [];
        _this.dataSet2 = [];
        _this.dataSet3 = [];
        _this.dataSet4 = [];
        _this.chartInstance = null;
        _this.chartInstance2 = null;
        _this.chartInstance3 = null;
        _this.chartInstance4 = null;
        return _this;
    }
    DayCollectionStatistic.prototype.mounted = function () {
        this.refreshData();
    };
    DayCollectionStatistic.prototype.handleResetForm = function () {
        this.queryParamsModel = {};
    };
    DayCollectionStatistic.prototype.refreshData = function () {
        var _this = this;
        this.repayService.queryProductTotalRepayAmount(__assign(__assign({}, this.queryParamsModel), { productType: 1 }), this.loading).subscribe(function (data) {
            _this.dataSet = [];
            data.forEach(function (item) {
                if (!item.productName.endsWith("撤诉")) {
                    _this.dataSet.push(item);
                }
            });
            _this.initChart();
        });
        this.repayService.queryProductTotalRepayAmount(__assign(__assign({}, this.queryParamsModel), { productType: 2 }), this.loading).subscribe(function (data) {
            _this.dataSet2 = [];
            data.forEach(function (item) {
                _this.dataSet2.push(item);
            });
            _this.initChart2();
        });
        this.repayService.queryGroupNameRepayRanking(__assign(__assign({}, this.queryParamsModel), { productType: 1 }), this.loading).subscribe(function (data) {
            _this.dataSet3 = [];
            data.forEach(function (item) {
                _this.dataSet3.push(item);
            });
            _this.initChart3();
        });
        this.repayService.queryGroupNameRepayRanking(__assign(__assign({}, this.queryParamsModel), { productType: 2 }), this.loading).subscribe(function (data) {
            _this.dataSet4 = [];
            data.forEach(function (item) {
                _this.dataSet4.push(item);
            });
            _this.initChart4();
        });
    };
    DayCollectionStatistic.prototype.initChart = function () {
        var chartDom = this.$refs.chart;
        this.chartInstance = echarts.init(chartDom);
        var unit = "万元";
        var option = {
            title: {
                text: "行调项目总还款金额(万元)",
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            legend: {
                data: ["还款金额"],
            },
            xAxis: {
                type: "category",
                data: this.dataSet.map(function (item) { return item.productName; }),
                axisLabel: {
                // rotate: 35, // 旋转 x 轴标签，防止过长重叠
                },
            },
            yAxis: {
                type: "value",
                // name: `还款金额：${unit}`, // 在 Y 轴顶部展示单位
                nameLocation: "end",
                nameTextStyle: {
                    padding: [0, 40, 20, 0],
                    fontWeight: "bold",
                },
            },
            dataZoom: [
                {
                    type: "slider",
                    show: true,
                    start: 0,
                    end: this.dataSet.length > 10 ? (10 / this.dataSet.length) * 100 : 100,
                },
            ],
            series: [
                {
                    name: "业绩（万元）",
                    type: "bar",
                    barWidth: 55,
                    data: this.dataSet.map(function (item) { return (item.repayAmount / 10000).toFixed(2); }),
                    itemStyle: {
                        color: "#006666",
                    },
                    label: {
                        show: true,
                        position: "top",
                        formatter: function (params) { return params.value + " " + unit; },
                        fontSize: 12,
                        color: "#333",
                    },
                    animation: true,
                    animationDuration: 1500,
                    animationEasing: "bounceOut",
                }
            ],
            animationDurationUpdate: 1000,
            animationEasingUpdate: "cubicOut",
        };
        this.chartInstance.setOption(option);
    };
    DayCollectionStatistic.prototype.initChart2 = function () {
        var chartDom = this.$refs.chart2;
        this.chartInstance2 = echarts.init(chartDom);
        var unit = "万元";
        var option = {
            title: {
                text: "诉中项目总还款金额(万元)",
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            legend: {
                data: ["还款金额"],
            },
            xAxis: {
                type: "category",
                data: this.dataSet2.map(function (item) { return item.productName; }),
                axisLabel: {
                // rotate: 35, // 旋转 x 轴标签，防止过长重叠
                },
            },
            yAxis: {
                type: "value",
                nameLocation: "end",
                nameTextStyle: {
                    padding: [0, 40, 20, 0],
                    fontWeight: "bold",
                },
            },
            dataZoom: [
                {
                    type: "slider",
                    show: true,
                    start: 0,
                    end: this.dataSet2.length > 10 ? (10 / this.dataSet2.length) * 100 : 100,
                },
            ],
            series: [
                {
                    name: "业绩（万元）",
                    type: "bar",
                    barWidth: 55,
                    data: this.dataSet2.map(function (item) { return (item.repayAmount / 10000).toFixed(2); }),
                    itemStyle: {
                        color: "#006666",
                    },
                    label: {
                        show: true,
                        position: "top",
                        formatter: function (params) { return params.value + " " + unit; },
                        fontSize: 12,
                        color: "#333",
                    },
                    animation: true,
                    animationDuration: 1500,
                    animationEasing: "bounceOut",
                }
            ],
            animationDurationUpdate: 1000,
            animationEasingUpdate: "cubicOut",
        };
        this.chartInstance2.setOption(option);
    };
    DayCollectionStatistic.prototype.initChart3 = function () {
        var chartDom = this.$refs.chart3;
        this.chartInstance3 = echarts.init(chartDom);
        var unit = "万元";
        var option = {
            title: {
                text: "行调项目小组还款金额(万元)",
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            legend: {
                data: ["还款金额"],
            },
            xAxis: {
                type: "category",
                data: this.dataSet3.map(function (item) { return item.name; }),
                axisLabel: {
                // rotate: 35, // 旋转 x 轴标签，防止过长重叠
                },
            },
            yAxis: {
                type: "value",
                nameLocation: "end",
                nameTextStyle: {
                    padding: [0, 40, 20, 0],
                    fontWeight: "bold",
                },
            },
            dataZoom: [
                {
                    type: "slider",
                    show: true,
                    start: 0,
                    end: this.dataSet3.length > 10 ? (10 / this.dataSet3.length) * 100 : 100,
                },
            ],
            series: [
                {
                    name: "业绩（万元）",
                    type: "bar",
                    barWidth: 55,
                    data: this.dataSet3.map(function (item) { return (item.repayAmount / 10000).toFixed(2); }),
                    itemStyle: {
                        color: "#006666",
                    },
                    label: {
                        show: true,
                        position: "top",
                        formatter: function (params) { return params.value + " " + unit; },
                        fontSize: 12,
                        color: "#333",
                    },
                    animation: true,
                    animationDuration: 1500,
                    animationEasing: "bounceOut",
                }
            ],
            animationDurationUpdate: 1000,
            animationEasingUpdate: "cubicOut",
        };
        this.chartInstance3.setOption(option);
    };
    DayCollectionStatistic.prototype.initChart4 = function () {
        var chartDom = this.$refs.chart4;
        this.chartInstance4 = echarts.init(chartDom);
        var unit = "万元";
        var option = {
            title: {
                text: "诉中项目小组还款金额(万元)",
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            legend: {
                data: ["还款金额"],
            },
            xAxis: {
                type: "category",
                data: this.dataSet4.map(function (item) { return item.name; }),
                axisLabel: {
                // rotate: 35, // 旋转 x 轴标签，防止过长重叠
                },
            },
            yAxis: {
                type: "value",
                nameLocation: "end",
                nameTextStyle: {
                    padding: [0, 40, 20, 0],
                    fontWeight: "bold",
                },
            },
            dataZoom: [
                {
                    type: "slider",
                    show: true,
                    start: 0,
                    end: this.dataSet4.length > 10 ? (10 / this.dataSet4.length) * 100 : 100,
                },
            ],
            series: [
                {
                    name: "业绩（万元）",
                    type: "bar",
                    barWidth: 55,
                    data: this.dataSet4.map(function (item) { return (item.repayAmount / 10000).toFixed(2); }),
                    itemStyle: {
                        color: "#006666",
                    },
                    label: {
                        show: true,
                        position: "top",
                        formatter: function (params) { return params.value + " " + unit; },
                        fontSize: 12,
                        color: "#333",
                    },
                    animation: true,
                    animationDuration: 1500,
                    animationEasing: "bounceOut",
                }
            ],
            animationDurationUpdate: 1000,
            animationEasingUpdate: "cubicOut",
        };
        this.chartInstance4.setOption(option);
    };
    DayCollectionStatistic.prototype.formattedStartDate = function () {
        var currentDate = new Date();
        var year = currentDate.getFullYear(); //获取当前年
        var month = String(currentDate.getMonth() + 1).padStart(2, "0"); //获取当前月
        var firstDay = "01"; //日
        return year + "-" + month + "-" + firstDay;
    };
    DayCollectionStatistic.prototype.formattedEndDate = function () {
        // 获取当前日期
        var currentDate = new Date();
        // 获取当前年份
        var year = currentDate.getFullYear();
        // 获取下个月的月份，使用 padStart 在月份小于 10 时在前面补零
        var month = String(currentDate.getMonth() + 2).padStart(2, "0");
        // 如果月份为 '13'，说明当前是12月，需要更新年份并将月份设置为 '01'
        if (month === "13") {
            year = year + 1;
            month = "01";
        }
        // 设置每个月的第一天为 '01'
        var firstDay = "01";
        // 构建下个月的第一天的日期字符串，格式为 'YYYY-MM-DD'
        var firstDayOfNextMonth = year + "-" + month + "-" + firstDay;
        // 计算当前月的最后一天，通过减去一天的毫秒数来得到
        var lastDayOfMonth = new Date(new Date(firstDayOfNextMonth).getTime() - 86400000);
        // 将最后一天的日期转换为 ISO 格式，并提取日期部分
        return lastDayOfMonth.toISOString().split("T")[0];
    };
    __decorate([
        Dependencies(RepayService)
    ], DayCollectionStatistic.prototype, "repayService", void 0);
    DayCollectionStatistic = __decorate([
        Layout("workspace"),
        Component({
            components: {
                PrincipalBatch: PrincipalBatch,
                DataForm: DataForm,
                DataBox: DataBox,
            },
        })
    ], DayCollectionStatistic);
    return DayCollectionStatistic;
}(Vue));
export default DayCollectionStatistic;
